/* eslint no-console:0 */

import Rails from "@rails/ujs"
import 'bootstrap'
import 'jquery'
import 'popper.js'
import tippy from 'tippy.js';
import AOS from 'aos';
import Plyr from 'plyr';
import '@stripe/stripe-js';

const images = require.context('../../images', true)

Rails.start()


setTimeout(() => {
    $('.alert-disappearing-wrapper').remove()
}, 5000)

document.addEventListener('DOMContentLoaded', function () {
    const player = new Plyr('#player');
    AOS.init();
    tippy('[data-tippy-content]');
});